import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import LocalizedLink from '../LocalizedLink';
import CustodianBoard from "../community/CustodianBoard";


const FaqListWrapper = styled.div`
  min-height: 400px;
`;


const AccordeonItemWrapper = styled.div`
  background: ${props => props.theme.colors.bgLighter };
  color: white;
`;

const AccordeonItemHeader = styled.div`
  cursor:pointer;
  padding: 1rem 2rem;
  color:${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};


  .arrow {
    float: right;
    transition: all 0.3s ease;
  }

  .opened.arrow{
    transform: rotate(0.5turn);
  }
`;

const AccordeonItemDescription = styled.div`
  margin: 2rem;
  padding-bottom:2rem;
  color: ${props => props.theme.colors.whiteDarkened};
  display:none;
  transition: all 3s ease;

  &.opened {
    display: block;
  }
  a {
   color: ${props => props.theme.colors.secondary};
   cursor:pointer;
   text-decotation: underline;
  }
  p {
    margin-top:1rem;
  }
`;



type AccordeonItemType = {
  title: string,
  description?: React.ReactNode,
  composedTranslate?: boolean
};

const AccordeonItem: React.FC< AccordeonItemType > = ( props ) => {
  
  const { t } = useTranslation();

  const [ opened, setOpened ] = useState( false );
  const { title, description, composedTranslate } = props;

  const updateState = ()=>{
    setOpened( !!!opened );
  }


  return (
    <AccordeonItemWrapper>
      <AccordeonItemHeader onClick={ updateState }>
        { t(title) }
        <span className={ `arrow ${ (opened) ? 'opened': '' }`} >▾</span>
      </AccordeonItemHeader>
      <AccordeonItemDescription className={ opened ? 'opened' : ''}>
        { props.children ? props.children : 
          ( composedTranslate ? description : t( (description as string) || '') ) }
      </AccordeonItemDescription>
    </AccordeonItemWrapper>
  );

}; 


const FaqList: React.FC<{}> = ( props ) => {
  const { t } = useTranslation();

  const mvpContent = (
    <Trans i18nKey='faqPage.mvpDescription'>
      The MVP is deployed here: <a href={'https://app.vigor.ai'} target={"_blank"} rel={"noopener noreferrer"}>app.vigor.ai.</a> We invite developers to join us in building Vigor here: <a href={ 'https://t.me/VIGORdev' } target={"_blank"} rel={"noopener noreferrer"} >t.me/VIGORdev</a>
    </Trans>
  );

  const vigorTestnetContent =(
    <Trans i18nKey='faqPage.vigorTestnetDescription'>
      The MVP is here: <a href={'https://app.vigor.ai'} target={"_blank"} rel={"noopener noreferrer"}>app.vigor.ai</a>
    </Trans>
  );

  const vigorCompareContent = (
      <p><LocalizedLink to="/#compareDefi">{t(`faqPage.vigorCompareDescription`)}</LocalizedLink></p>
  );

  const smartContractContent =  (
    <Trans i18nKey='faqPage.smartContractDescription'>
      Yes, please visit our <a href = { 'https://gitlab.com/vigorstablecoin' } target = "_blank">repository</a>
    </Trans>
  );


  const FAQ = [{
    title: 'faqPage.mvp',
    description: mvpContent,
    composedTranslate: true
  }, 
  {
    title: `faqPage.stablecoins`,
    description: `faqPage.stablecoinsDescription`
  }, 
  {
    title: `faqPage.borrower`,
    description: `faqPage.borrowerDescription`
  }, 
  {
    title: `faqPage.secureLoan`,
    description: `faqPage.secureLoanDescription`
  }, 
  {
    title: `faqPage.collateralFalls`,
    description: `faqPage.collateralFallsDescription`
  }, 
  {
    title: `faqPage.EOSCollateral`,
    description: `faqPage.EOSCollateralDescription`
  }, 
  {
    title: `faqPage.additionalCrypto`,
    description: `faqPage.additionalCryptoDescription`
  }, 
  {
    title: `faqPage.vigorPlatform`,
    description: `faqPage.vigorPlatformDescription`
  }, 
  {
    title: `faqPage.vigorTestnet`,
    description: vigorTestnetContent,
    composedTranslate: true
  }, 
  {
    title: `faqPage.supplyVIG`,
    description: `faqPage.supplyVIGDescription`
  }, 
  {
    title: `faqPage.useCases`,
    description: `faqPage.useCasesDescription`
  }, 
  {
    title: `faqPage.isVig`,
    description: `faqPage.isVigDescription`
  }, 
  {
    title: `faqPage.triggersBailout`,
    description: `faqPage.triggersBailoutDescription`
  }, 
  {
    title: `faqPage.finalReserve`,
    description: `faqPage.finalReserveDescription`
  }, 
  {
    title: `faqPage.useVigor`,
    description: `faqPage.useVigorDescription`
  }, 
  {
    title: `faqPage.overcollaterization`,
    description: `faqPage.overcollaterizationDescription`
  }, 
  {
    title: `faqPage.collateralSecured`,
    description: `faqPage.collateralSecuredDescription`
  }, 
  {
    title: `faqPage.priceRemainStable`,
    description: `faqPage.priceRemainStableDescription`
  }, 
  {
    title: `faqPage.penalties`,
    description: `faqPage.penaltiesDescription`
  }, 
  {
    title: `faqPage.otherStablecoins`,
    description: `faqPage.otherStablecoinsDescription`
  }, 
  {
    title: `faqPage.vigorCompare`,
    description: vigorCompareContent,
    composedTranslate: true
  }, 
  {
    title: `faqPage.EOSBlockchain`,
    description: `faqPage.EOSBlockchainDescription`
  }, 
  {
    title: `faqPage.secondaryMarket`,
  description: `faqPage.secondaryMarketDescription`
  },
  {
    title: `faqPage.smartContract`,
  description: smartContractContent,
  composedTranslate: true
  }
  ];
  


  return (
    <FaqListWrapper>
      <ul>
        { FAQ.map( ( item, key ) =>(
          <AccordeonItem key={ key } {...item} />
        ))}

        <AccordeonItem key="item-n" title={ t('faqPage.team') } >
          <p>{t(`custodianBoard.text`)}</p>
          <p><LocalizedLink to="/community#custodianBoard">{t(`custodianBoard.title`)}</LocalizedLink></p>

        </AccordeonItem>
        
      </ul>
    </FaqListWrapper>
    
  );
};

export default FaqList
